
import { Options, Vue } from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
import { IVolume } from "@/types/api.type.ts";
import { getImageUrl } from "@/share/fetch.ts";
import Component from "vue-class-component";

@Options({
  name: "Volumes",
  methods: {
    getImageUrl: getImageUrl,
  },
})
export default class Volumes extends Vue {
  @Prop()
  volumes: IVolume[] | undefined;
}
